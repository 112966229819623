<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
        <h6 class="inline"><i class="fa-duotone fa-square-info"></i> Status bearbeiten</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeModal', 'ConfigWorkflowStates');$store.commit('closeConfigWorkflowStates');">
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
          class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
          class="fa-duotone fa-trash"></i> {{ $t("globals.delete") }}
        </button>
      </div>
      <div class="card-body">

              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label> Titel</label>
                    <input class="form-control" type="text" v-model="d.title">
                  </div>
                </div>


                <div class="col-3">
                  <div class="form-group">
                    <label>Reihenfolge</label>
                    <input class="form-control" type="text" v-model="d.ordering">
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label>Zeichen</label>
                    <input class="form-control" type="text" v-model="d.icon"  >
                  </div>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <label>Farbe</label>
                    <input class="form-control" type="color" v-model="d.color">
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Für Kunden Sichtbar</label>
                    <select class="form-select" v-model="d.official" >
                      <option value="yes" >{{$t("globals.yes")}}</option>
                      <option value="no">{{$t("globals.no")}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Status</label>
                    <select class="form-select" v-model="d.state" >
                      <option v-for="option in stateOptions" :value="option.value" :key="option.value">{{ option.label }}</option>
                    </select>
                  </div>
                </div>

          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Workflow/IssueStates/Layouts/Headline.vue";
import $ from "jquery";

export default {
  name: "StatesDetails",
  components: {
    Headline,
    Editor2
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs", "getConfigWorkflowStates"])
  },
  data() {
    return {
      d: {
        title: "",
        state: "calc",
        ordering: 0,
      },
      isNew: true,
      stateOptions: [
        {value: "open", label: "offen"},
        {value: "waiting", label: "wartend"},
        {value: "reject", label: "Wiedervorlage"},
        {value: "closed", label: "geschlossen"},
        {value: "deleted", label: "gelöscht"},
        {value: "permanentClosed", label: "dauerhaft geschlossen"},
      ],
    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "configs/issueStates/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products
          this.$store.dispatch("loadConfig", {type: "issue", subType: 'states', percent: 94, message: 'fast fertig... kurz vor Ziel'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Status wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowStates');
          });
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "configs/issueStates/", VueInner.d, {headers}).then(() => {


          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "issue", subType: 'states', percent: 94, message: 'fast fertig... kurz vor Ziel'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigWorkflowStates');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Akquise-Stand wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigWorkflowStates');
          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll der Status wirklich gelöscht werden?', 'Status löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "configs/issueStates/" + VueInner.d.id, {headers}).then(() => {
          // close
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type: "issue", subType: 'states', percent: 94, message: 'fast fertig... kurz vor Ziel'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Status wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigWorkflowStates');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },
    loadDraft() {
      if (this.$route.params.id) {
         this.d = this.getConfigWorkflowStates

        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    $route(to, from) {
      this.loadDraft();
    }
  }
};
</script>

