<template>
  <div class="input-group" v-if="$route && $route.matched && $route.matched.length">
    <template v-if="$route.matched && $route.matched[0].name === 'customers'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.customers.search.value"
             @keyup.enter="$store.state.lists.customers.offset = 0;$store.dispatch('customersFilter');$store.commit('closeCustomer');"
             placeholder="Kunden suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.customers.offset = 0;$store.dispatch('customersFilter');$store.commit('closeCustomer')"
                type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary" style="margin-left: -1px;"
                @click="$store.dispatch('resetFilter', 'customers');$store.dispatch('customersFilter');$store.commit('closeCustomer');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
        <button type="button" class="btn btn-outline-primary" tooltip="Erweiterte Suche" position="right" @click="startPrefixSearch"><i class="fa-duotone fa-search-plus"></i>
        </button>
      </div>

    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'products'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.products.search.value"
             @keyup.enter="$store.state.lists.products.offset = 0;$store.dispatch('productsFilter');$store.commit('closeProduct');"
             placeholder="Produkte suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.products.offset = 0;$store.dispatch('productsFilter');$store.commit('closeProduct')"
                type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'products');$store.state.filters.products.direction.value = 'asc';$store.state.filters.products.ordering.value = 'p_title';$store.dispatch('productsFilter');$store.commit('closeProduct');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Documents'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.documents.search.value"
             @keyup.enter="$store.state.lists.documents.offset = 0;$store.dispatch('documentsFilter');$store.commit('closeDocument');"
             placeholder="Dokumente suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.documents.offset = 0;$store.dispatch('documentsFilter');$store.commit('closeDocument')"
                type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'documents');$store.dispatch('documentsFilter');$store.commit('closeDocument');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Sepa'">
      <input type="text" class="form-control input-small red" id="filter_search"
             v-model="$store.state.filters.sepas.search.value"
             @keyup.enter="$store.state.lists.sepas.offset=0;$store.dispatch('sepasFilter');"
             placeholder="Sepa suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.sepas.offset=0;$store.dispatch('sepasFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.sepas.search.value = '';$store.state.lists.sepas.offset=0;$store.dispatch('sepasFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Tasks'">
      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.tasks.search.value"
             @keyup.enter="$store.state.lists.tasks.offset = 0;$store.dispatch('tasksFilter');$store.dispatch('setTask', null);$router.push({name: 'Tasks'});"
             placeholder="Tickets suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.tasks.offset = 0;$store.dispatch('tasksFilter');$store.dispatch('setTask', null);$router.push({name: 'Tasks'});"
                type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'tasks');$store.dispatch('tasksFilter');$store.dispatch('setTask', null);$router.push({name: 'Tasks'});"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'drafts'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.drafts.search.value"
             @keyup.enter="$store.state.lists.drafts.offset=0;$store.dispatch('draftsFilter');"
             placeholder="Vorlagen suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.drafts.offset=0;$store.dispatch('draftsFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'drafts');$store.dispatch('draftsFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Events'">
      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.events.search.value"
             @keyup.enter="$store.state.lists.events.offset = 0;$store.dispatch('eventsFilter');"
             placeholder="Events suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.events.offset = 0;$store.dispatch('eventsFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'events');$store.dispatch('eventsFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'Projects'">
      <input type="text" class="form-control input-small purple" id="filter_search"
             v-model="$store.state.filters.projects.search.value"
             @keyup.enter="$store.dispatch('projectsFilter');" placeholder="Projekte suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.dispatch('projectsFilter');" type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.projects.search.value = '';$store.dispatch('projectsFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>
    </template>
    <template  v-if="$route.matched && $route.matched[0].name === 'ConfigAccountingKeys'">
      <input type="text" class="form-control input-small red" id="filter_search"
             v-model="$store.state.filters.accountingKeys.search.value"

             placeholder="Buchungsschlüssel suchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"  type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.state.filters.accountingKeys.search.value = '';"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>

    </template>
    <template v-if="$route.matched && $route.matched[0].name === 'LangOverrides'">
      <input type="text" class="form-control input-small" id="filter_search"
             v-model="$store.state.filters.languageOverrides.search.value"
             @keyup.enter="$store.state.lists.languageOverrides.offset = 0;$store.dispatch('languageOverridesFilter');"
             placeholder="Sprachoverrides durchsuchen ..."/>
      <div class="input-group-append">
        <button class="btn btn-outline-primary"
                @click="$store.state.lists.languageOverrides.offset = 0;$store.dispatch('languageOverridesFilter');"
                type="button"><i
          class="fa-duotone fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-outline-secondary"
                @click="$store.dispatch('resetFilter', 'languageOverrides');$store.dispatch('languageOverridesFilter');"
                type="button"><i
          class="fa-duotone fa-x fa-swap-opacity"></i></button>
      </div>

    </template>

  </div>
  <Modal v-if="prefixSearchPopup">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="searchItem activeProduct">
          <div class="card">
            <div class="card-header">Suchkriterium wählen <button class="btn-close float-end" @click="prefixSearchPopup = false"></button></div>
            <div class="card-body">
            <template v-for="pre in prefixes">
              <button type="button" class="btn btn-outline-primary btn-sm" @click="usePrefixForSearch(pre.prefix)" style="margin: 5px;">{{ pre.name }}</button>
            </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "searchbar",
  // data
  data() {
    return {
      prefixSearchPopup: false,
      prefixes: [
        {name: "Postleitzahl", prefix: "c_zipcode"},
        {name: "Ort", prefix: "c_city"},
        {name: "Land", prefix: "c_country"},
        {name: "Bundesland", prefix: "c_state"},
      ],
    };
  },
  computed: {
    ...mapActions(["logout"]),
  },
  methods: {
    startPrefixSearch() {
      this.prefixSearchPopup = true;
    },
    usePrefixForSearch(prefix) {
      this.$store.state.filters.customers.search.prefix = prefix;
      this.$store.state.filters.customers.search.value = prefix+":";

      document.getElementById("filter_search").focus();
      this.prefixSearchPopup = false;
    },
  },
}
</script>

